import React from "react";
import PropTypes from "prop-types";
import NegociatedItem from "app/pages/FicheProduit/NegociatedList/NegociatedItem";
import { FormattedMessage } from "react-intl";
import "./NegociatedList.scss";

const NegociatedList = ({ negociatedList, specialOffer, offerWithTransportLabel }) => {
	return (
		<div className="negociated-list">
			<h4 className="negociated-list__title">
				<FormattedMessage id="product.page.negociated.title" />
			</h4>
			<ul className="negociated-list__list">
				{negociatedList.map((negociated, index) => {
					return (
						<li key={index} className="negociated-list__item">
							<NegociatedItem
								type={negociated.type}
								label={negociated.label}
								isTransportOnly={negociated.isTransportOnly}
								offerWithTransportLabel={offerWithTransportLabel}
								specialOffer={specialOffer}
							/>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

NegociatedList.propTypes = {
	negociatedList: PropTypes.array,
	specialOffer: PropTypes.object,
	offerWithTransportLabel: PropTypes.string,
};

NegociatedList.defaultProps = {
	negociatedList: [],
	specialOffer: {},
};

export default React.memo(NegociatedList);

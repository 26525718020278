import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./Formalities.scss";

/* eslint react/no-danger: 0 */
const Formalities = ({ intro, vaccine, visa, identity, country }) => {
	return (
		<div className="formalities">
			<h3 className="formalities__headline">
				<FormattedMessage
					id="useful.information.formalities.headline"
					values={{ country: country }}
				/>
			</h3>
			<div className="formalities__intro" dangerouslySetInnerHTML={{ __html: intro }} />
			<div className="formalities-list">
				{vaccine && (
					<div className="formalities-list__item">
						<h4 className="formalities-list__headline">
							<FormattedMessage id="useful.informtion.vaccine.title" />
						</h4>
						<p
							className="formalities-list__description"
							dangerouslySetInnerHTML={{ __html: vaccine }}
						/>
					</div>
				)}

				{visa && (
					<div className="formalities-list__item">
						<h4 className="formalities-list__headline">
							<FormattedMessage id="useful.informtion.visa.title" />
						</h4>
						<p
							className="formalities-list__description"
							dangerouslySetInnerHTML={{ __html: visa }}
						/>
					</div>
				)}

				{identity && (
					<div className="formalities-list__item">
						<h4 className="formalities-list__headline">
							<FormattedMessage id="useful.informtion.identity.title" />
						</h4>
						<p
							className="formalities-list__description"
							dangerouslySetInnerHTML={{ __html: identity }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

Formalities.propTypes = {
	country: PropTypes.string,
	vaccine: PropTypes.string,
	intro: PropTypes.string,
	visa: PropTypes.string,
	identity: PropTypes.string,
};

export default React.memo(Formalities);

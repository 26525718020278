import React from "react";
import PropTypes from "prop-types";
import IconCovid from "app/pages/.shared/static/icons/IconCovid";
import "./SanitaryMessage.scss";
import { FormattedMessage } from "react-intl";
import MoreDetails from "app/pages/.shared/MoreDetails";
import List from "app/pages/.shared/List";

const SanitaryMessage = ({ intro, details }) => {
	return (
		<div className="sanitary-message">
			<h3 className="sanitary-message__title">
				<IconCovid />
				<FormattedMessage id="product.sanitary.message.title" tagName="p" />
			</h3>

			{intro.length > 0 ? (
				intro.map((text, index) => {
					return <p key={index} dangerouslySetInnerHTML={{ __html: text }} />;
				})
			) : (
				<FormattedMessage id="product.sanitary.message.desc" tagName="p" />
			)}

			{details.length > 0 && (
				<MoreDetails
					expandedLabel={
						<FormattedMessage
							id="product.page.less.details"
							description="collapse less details label"
							defaultMessage="- Less details"
						/>
					}
					collapsedLabel={
						<FormattedMessage
							id="product.page.more.details"
							description="collapse more details label"
							defaultMessage="+ More details"
						/>
					}
					headerPosition={"bottom"}
				>
					<List items={details} enableHTMLFormatting={true} />
				</MoreDetails>
			)}
		</div>
	);
};

SanitaryMessage.propTypes = {
	intro: PropTypes.array,
	details: PropTypes.array,
};

SanitaryMessage.defaultProps = {
	intro: [],
	details: [],
};

export default React.memo(SanitaryMessage);

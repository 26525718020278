import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchSDPFilters } from "app/pages/SmartDP/smartDPActionCreators";
import { bindActionCreators } from "redux";
import SmartDP from "app/pages/SmartDP/SmartDP";
import { getPartnerMarketing } from "app/reducers/partnerSelector";
import { resetAllSDPProductsFilter } from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";

const SmartDPContainer = props => {
	const { fetchSDPFilters } = props;

	useEffect(() => {
		fetchSDPFilters();
	}, []);

	return <SmartDP {...props} />;
};

const mapStateToProps = state => {
	return {
		destinations: state.smartDP.destinations,
		departureCities: state.smartDP.departureCities,
		cabins: state.smartDP.cabins,
		departureDateMin: state.smartDP.departureDateMin,
		departureDateMax: state.smartDP.departureDateMax,
		booking: state.booking,
		selectedAccommodation: state.ficheProduit,
		marketing: getPartnerMarketing(state),
		fetchSDPProductsStatus: state.smartDP.status,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			fetchSDPFilters,
			resetAllSDPProductsFilter,
		},
		dispatch
	);
}

SmartDPContainer.propTypes = {
	fetchSDPFilters: PropTypes.func,
	resetAllSDPProductsFilter: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDPContainer);

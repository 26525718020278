import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import "./TripAdvisor.scss";

const TripAdvisor = ({ rating, reviewsCount }) => {
	const ratingValue = rating.toString().indexOf(".") === -1 ? rating + ".0" : rating;

	return (
		<div className="tripadvisor">
			<div className="tripadvisor__rating">
				<img
					src={`https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${ratingValue}-MCID-5.svg`}
					alt="Trip advisor rating"
				/>
			</div>

			{reviewsCount > 0 && (
				<div className="tripadvisor__reviews">
					<FormattedPlural
						value={reviewsCount}
						one={
							<FormattedMessage
								id="product.review.count.singular"
								values={{
									reviewsCount: 1,
								}}
							/>
						}
						other={
							<FormattedMessage
								id="product.review.count.plural"
								values={{
									reviewsCount,
								}}
							/>
						}
					/>
				</div>
			)}
		</div>
	);
};

TripAdvisor.propTypes = {
	rating: PropTypes.number.isRequired,
	reviewsCount: PropTypes.number,
};

export default React.memo(TripAdvisor);

import React from "react";
import Formalities from "./Formalities.jsx";
import Transport from "./Transport.jsx";
import TravelDocuments from "./TravelDocuments.jsx";
import GoodToKnow from "./GoodToKnow.jsx";
import isEmpty from "lodash/isEmpty";
import { usefulInformationShape } from "app/utils/propTypes";
import { FormattedMessage } from "react-intl";
import "./UsefulInformation.scss";

const UsefulInformation = ({ usefulInformations }) => {
	return (
		<div className="useful-information">
			<h2 className="useful-information__headline">
				<FormattedMessage id="useful.information.title" />
			</h2>

			<div className="useful-information__item">
				{!isEmpty(usefulInformations.goodtoKnow) && (
					<GoodToKnow description={usefulInformations.goodtoKnow} />
				)}
			</div>
			<div className="useful-information__item">
				{usefulInformations.travelDocuments &&
					usefulInformations.travelDocuments.length > 0 && (
						<TravelDocuments description={usefulInformations.travelDocuments} />
					)}
				{usefulInformations.transport && usefulInformations.transport.length > 0 && (
					<Transport description={usefulInformations.transport} />
				)}
			</div>
			<div className="useful-information__item">
				{!isEmpty(usefulInformations.formalities) && (
					<Formalities {...usefulInformations.formalities} />
				)}
				{!isEmpty(usefulInformations.formalitiesOut) && (
					<Formalities {...usefulInformations.formalitiesOut} />
				)}
			</div>
		</div>
	);
};

UsefulInformation.propTypes = {
	usefulInformations: usefulInformationShape,
};

UsefulInformation.defaultProps = {
	usefulInformations: {},
};

export default React.memo(UsefulInformation);

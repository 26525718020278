import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SmartDPHeader from "app/pages/SmartDP/SmartDPHeader/SmartDPHeader";
import { useHistory, useRouteMatch } from "react-router-dom";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import { isWhiteLabel } from "app/reducers/brandSelector";
import { logout } from "app/pages/Auth/AuthActions";
import {
	getAvailableCreditNotesTotal,
	getAvailableCreditsTotal,
	hasAnyCreditNotes,
} from "app/pages/Account/MyCoupons/couponSelector";
import { getOpenCustomerRequestsCount } from "app/pages/Account/MyBookings/myBookingsSelector";
import { bindActionCreators } from "redux";
import { BRANDS } from "app/constants";
import { brandPropTypes } from "app/utils/propTypes";

const SmartDPHeaderContainer = props => {
	const { logout } = props;
	const { resolution } = useContext(AppGlobalsContext);
	const history = useHistory();
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const isSDPSearchPage = useRouteMatch({
		path: ["/sdp/search", "/"],
		exact: true,
		sensitive: true,
	});

	const isSDPListing = useRouteMatch({
		path: ["/sdp/listing"],
	});

	const handleLogoutClick = useCallback(() => {
		logout();
		history.push({ pathname: "/" });
	}, []);

	const hideBackButton = isSDPSearchPage || (props.brand === BRANDS.TO && isSDPListing);

	return (
		<SmartDPHeader
			{...props}
			logoutClick={handleLogoutClick}
			displayBackButton={!hideBackButton}
			isLogoSmall={!isSDPSearchPage && isMobile}
		/>
	);
};

SmartDPHeaderContainer.propTypes = {
	brand: brandPropTypes,
	logout: PropTypes.func,
};

const mapStateToProps = state => {
	const partner = state.partner;

	return {
		shouldDisplayCreditNotesEntry: hasAnyCreditNotes(state),
		isWhiteLabel: isWhiteLabel(state),
		userIsConnected: isAuthenticated(state),
		operatorEmail: state.auth.operatorEmail,
		enableSponsorship: partner.enableSponsorship,
		enableCredits: partner.enableCredits,
		creditsAmount: getAvailableCreditsTotal(state),
		creditNotesAmount: getAvailableCreditNotesTotal(state),
		partnercode: partner.code,
		brand: state.brand.code,
		openCustomerRequestCount: getOpenCustomerRequestsCount(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ logout }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDPHeaderContainer);

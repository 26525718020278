import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import IconNegoPlus from "app/pages/.shared/IconNegoPlus";
import { NEGOCIATED_TYPES } from "app/constants";
import IconNegoBed from "app/pages/.shared/IconNegoBed";
import IconNegoPlane from "app/pages/.shared/IconNegoPlane";
import IconNegoTransfer from "app/pages/.shared/IconNegoTransfer";
import IconNegoDinner from "app/pages/.shared/IconNegoDinner";
import get from "lodash/get";
import "./NegociatedItem.scss";
import IconNegoTrain from "app/pages/.shared/IconNegoTrain";
import IconNegoTransport from "app/pages/.shared/IconNegoTransport";
import { useIntl } from "react-intl";
import LineClamp from "app/pages/.shared/LineClamp/LineClamp";

const NegociatedItem = ({
	type,
	label,
	enableLineClamp,
	lineClampFullText,
	isTransportOnly,
	specialOffer,
	offerWithTransportLabel,
	labelPrefix,
}) => {
	let picto = <IconNegoPlus />;
	const specialOfferTitleColor = get(specialOffer, "ui.titleColor");
	const specialOfferPictoUrl = get(specialOffer, "ui.pictoUrl");

	const intl = useIntl();

	const negocatedSuffix = intl.formatMessage(
		{ id: "search.engine.negociated.with.offer" },
		{ offer: offerWithTransportLabel }
	);

	let labelToDisplay = label;

	if (isTransportOnly && type !== NEGOCIATED_TYPES.SPECIAL_OFFER) {
		labelToDisplay = `${label} (${negocatedSuffix})`;
	}

	if (type === NEGOCIATED_TYPES.ACCOMMODATION) {
		picto = <IconNegoBed />;
	} else if (type === NEGOCIATED_TYPES.FLIGHT) {
		picto = <IconNegoPlane />;
	} else if (type === NEGOCIATED_TYPES.TRANSFER) {
		picto = <IconNegoTransfer />;
	} else if (type === NEGOCIATED_TYPES.BOARD) {
		picto = <IconNegoDinner />;
	} else if (type === NEGOCIATED_TYPES.TRAIN) {
		picto = <IconNegoTrain />;
	} else if (type === NEGOCIATED_TYPES.TRANSPORTATION) {
		picto = <IconNegoTransport />;
	} else if (type === NEGOCIATED_TYPES.SPECIAL_OFFER && specialOfferPictoUrl) {
		picto = <img src={specialOfferPictoUrl} />;
	}

	const negoClass = classNames("negociated-item", {
		"negociated-item--other": !type || type === NEGOCIATED_TYPES.OTHER,
		"negociated-item--special-offer": type === NEGOCIATED_TYPES.SPECIAL_OFFER,
	});

	return (
		<React.Fragment>
			<div
				className={negoClass}
				style={{ color: type === NEGOCIATED_TYPES.SPECIAL_OFFER && specialOfferTitleColor }}
			>
				<i className="negociated-item__picto">{picto}</i>{" "}
				<div className="negociated-item__main">
					{enableLineClamp ? (
						<LineClamp
							lineClampFullText={lineClampFullText}
							enableShowMore={true}
							lineCount={1}
							text={
								<span
									className="negociated-item__label"
									dangerouslySetInnerHTML={{ __html: labelToDisplay }}
								/>
							}
						/>
					) : (
						<>
							<span
								className="negociated-item__label"
								dangerouslySetInnerHTML={{ __html: labelToDisplay }}
							/>
							{labelPrefix}
						</>
					)}
				</div>
			</div>
			{type === NEGOCIATED_TYPES.SPECIAL_OFFER && (
				<span
					className="negociated-item__special-offer-details"
					style={{ color: specialOfferTitleColor }}
					dangerouslySetInnerHTML={{ __html: specialOffer.details }}
				/>
			)}
		</React.Fragment>
	);
};

NegociatedItem.propTypes = {
	type: PropTypes.string,
	offerWithTransportLabel: PropTypes.string,
	label: PropTypes.string,
	labelPrefix: PropTypes.element,
	isTransportOnly: PropTypes.bool,
	specialOffer: PropTypes.object,
	lineClampFullText: PropTypes.object,
	enableLineClamp: PropTypes.bool,
};

NegociatedItem.defaultProps = {
	specialOffer: {},
};

export default React.memo(NegociatedItem);

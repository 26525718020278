import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./GoodToKnow.scss";

const GoodToKnow = ({ description }) => {
	return (
		<div className="good-to-know">
			<h3 className="good-to-know__headline">
				<FormattedMessage id="useful.information.good.to.knows.headline" />
			</h3>
			<div className="good-to-know__description">
				<Paragraphs paragraphs={description} enableHTMLFormatting={true} />
			</div>
		</div>
	);
};

GoodToKnow.propTypes = {
	description: PropTypes.arrayOf(PropTypes.string),
};

GoodToKnow.defaultProps = {
	description: [],
};

export default React.memo(GoodToKnow);

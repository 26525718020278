import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SmartDPHotelDescription from "app/pages/SmartDP/Quote/HotelDescription/SmartDPHotelDescription";
import { bindActionCreators } from "redux";
import { fetchSDPAccommodationDescription } from "app/pages/SmartDP/smartDPActionCreators";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { closeSlideshow, openSlideshow } from "app/pages/.shared/Slideshow/SlideShowActionCreators";
import { parseQueries } from "app/utils/routerUtils";
import { checkForSpecialOffer } from "app/pages/Booking/Payment/paymentSelector";
import get from "lodash/get";

const SmartDPHotelDescriptionContainer = props => {
	const { quotationCode, closeSlideshow, fetchSDPAccommodationDescription } = props;
	const params = useParams();
	const location = useLocation();

	useEffect(() => {
		const query = parseQueries(location.search);
		const { accommodationId, code } = params;
		const { accommodationId: accommodationIdFromQuery } = query;

		const codeForPayload = code || quotationCode;
		const accommodationIdForPayload = accommodationId || accommodationIdFromQuery;

		if (codeForPayload && accommodationIdForPayload) {
			fetchSDPAccommodationDescription({
				code: get(location, "state.code") || codeForPayload,
				accommodationId: accommodationIdForPayload,
			});
		}

		return () => {
			closeSlideshow();
		};
	}, [location.search, location.state, params.accommodationId, params.code, quotationCode]);

	return <SmartDPHotelDescription {...props} />;
};

SmartDPHotelDescriptionContainer.propTypes = {
	quotationCode: PropTypes.string,
	fetchSDPAccommodationDescription: PropTypes.func,
	closeSlideshow: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		hotel: state.ficheProduit,
		quotationCode: state.smartDP.code,
		slideshow: state.slideshow,
		hasSdpSpecialOffer: checkForSpecialOffer(state), // true si le back renvoi au moins un vol remboursable
		specialOfferInfos: state.ficheProduit.specialOffer,
		googleAPIKey: state.envVars.GOOGLE_API_KEY,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPAccommodationDescription,
			closeSlideshow,
			openSlideshow,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDPHotelDescriptionContainer);

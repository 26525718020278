import { getStore } from "app/configureStore";
import env from "app/utils/env";
import axios from "axios";
import {
	FETCH_SDP_FILTERS,
	FETCH_SDP_QUOTE,
	SAVE_SDP_SEARCH_QUERY_STRING,
	SAVE_SELECTED_SDP_PRODUCT,
	SHOW_MORE_SDP_PRODUCTS,
	FETCH_SDP_ACCOMMODATION_DESCRIPTION,
	CHECK_SDP_AVAILABILITIES,
} from "app/pages/SmartDP/smartDPActionTypes";
import { getCredentials } from "app/utils/auth";

export const saveSDPProduct = accommodation => {
	return {
		type: SAVE_SELECTED_SDP_PRODUCT,
		accommodation,
	};
};

export const fetchSDPFilters = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_SDP_FILTERS,
		promise: axios.get(`${endpoint}/${state.productsVisibility}/${shop}/sdp/filters.json`),
	};
};

export const fetchSdpQuote = quotationPayload => {
	const { token } = getCredentials();

	// @see https://perfectstay.atlassian.net/browse/FUX-21
	// On a besoin de passer le token uniquement pour savoir si un operateur est connecté ou non.
	// On effet, le token contiendra l'email de l'operator et le service de quote nous renverra l'email de l'operator
	const headers = {
		Authorization: token,
		"Content-type": "application/json",
	};

	return {
		type: FETCH_SDP_QUOTE,
		promise: axios.post(
			`${env("QUOTE_API_URL")}/sdp/quote`,
			{
				...quotationPayload,
			},
			{
				headers,
			}
		),
	};
};

export const showMoreSDPProducts = () => {
	return {
		type: SHOW_MORE_SDP_PRODUCTS,
	};
};

export const saveSDPSearchQueryString = search => {
	return {
		type: SAVE_SDP_SEARCH_QUERY_STRING,
		search,
	};
};

export const fetchSDPAccommodationDescription = ({ code, accommodationId }) => {
	return {
		type: FETCH_SDP_ACCOMMODATION_DESCRIPTION,
		promise: axios.post(`${env("QUOTE_API_URL")}/sdp/accommodationDescription`, {
			code,
			accommodationId,
		}),
	};
};

export const checkSDPAvailibilities = ({ code, accommodationId, boardCode, price }) => {
	return {
		type: CHECK_SDP_AVAILABILITIES,
		promise: axios.post(`${env("QUOTE_API_URL")}/sdp/accommodationQuote`, {
			code,
			accommodationId,
			boardCode,
			price,
		}),
	};
};

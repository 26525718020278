import React, { useCallback, useContext } from "react";
import "./SmartDPHotelDescription.scss";
import loadable from "@loadable/component";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ProductNameWithCategory from "app/pages/.shared/ProductNameWithCategory/ProductNameWithCategory";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import TripAdvisor from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisor";
import PropTypes from "prop-types";
import Collapse from "rc-collapse";
import { FormattedMessage } from "react-intl";
import SmartDPPatchwork from "app/pages/SmartDP/Quote/SmartDPPatchwork";
import { sendTagOnOpenProductCarouselDesktop } from "app/utils/analytics";
import { PRODUCT_PAGE_CODE_STATUS, SLIDE_SHOW_PHOTO_TYPES } from "app/constants";
import LineClampViewMore from "app/pages/.shared/LineClampViewMore/LineClampViewMore";
import SanitaryMessage from "app/pages/FicheProduit/components/Accommodation/SanitaryMessage/SanitaryMessage";
import SimpleProgressiveImg from "app/pages/.shared/SimpleProgressiveImg";
import NegociatedList from "app/pages/FicheProduit/NegociatedList/NegociatedList";
import List from "app/pages/.shared/List";
import UsefulInformation from "app/pages/FicheProduit/components/UsefulInformation/UsefulInformation";
import SpecialOfferBadge from "app/pages/.shared/SpecialOffer/SpecialOfferBadge/SpecialOfferBadge";
import SmartDPHotelDescriptionLoader from "app/pages/SmartDP/Quote/HotelDescription/SmartDPHotelDescriptionLoader";

const PhotoCarousel = loadable(() => import("app/pages/.shared/PhotoCarousel.jsx"));

const FacilitySection = loadable(() => import("./FacilitySection"));

const LocationSection = loadable(() => import("./LocationSection"));

const SlideShow = loadable(() => import("app/pages/.shared/Slideshow/SlideShow.jsx"));

const PHOTO_PRINT_IMG_SIZES = {
	small: { width: 800 },
	medium: { width: 800 },
	large: { width: 800 },
	xlarge: { width: 800 },
};

const SmartDPHotelDescription = ({
	hotel,
	slideshow,
	closeSlideshow,
	openSlideshow,
	printMode,
	hasSdpSpecialOffer,
	specialOfferInfos,
	googleAPIKey,
}) => {
	const { resolution } = useContext(AppGlobalsContext);

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const sanitaryInformations = get(hotel, "sanitaryInformations", {});

	const openPhotosSlideshow = useCallback(() => {
		sendTagOnOpenProductCarouselDesktop();
		openSlideshow(SLIDE_SHOW_PHOTO_TYPES.PHOTOS);
	}, []);

	const hotelDesc =
		hotel.status !== PRODUCT_PAGE_CODE_STATUS.SUCCESS ? (
			<div className="sdp-hotel-description">
				<SmartDPHotelDescriptionLoader />
			</div>
		) : (
			<div className="sdp-hotel-description">
				<div className="sdp-hotel-description__photo">
					{isMobile && !printMode && get(hotel, "photos.length") > 0 && (
						<PhotoCarousel photos={hotel.photos} resolution={resolution} />
					)}
					{!isMobile && !printMode && slideshow.isSlideshowOpened && (
						<SlideShow
							isOpened={slideshow.isSlideshowOpened}
							close={closeSlideshow}
							photos={hotel.photos}
						/>
					)}
					{hasSdpSpecialOffer && (
						<div className="sdp-hotel-description__badge">
							<SpecialOfferBadge
								background={get(specialOfferInfos, "ui.background")}
								title={specialOfferInfos.title}
								pictoUrl={get(specialOfferInfos, "ui.pictoUrl")}
							/>
						</div>
					)}
				</div>
				<div className="sdp-hotel-description__body">
					<div className="sdp-hotel-description__name">
						<ProductNameWithCategory category={hotel.category} name={hotel.name} />
					</div>

					<div className="sdp-hotel-description__location">{hotel.location}</div>
					{!isEmpty(hotel.tripadvisor) &&
						get(hotel, "tripadvisor.num_reviews") > 0 &&
						get(hotel, "tripadvisor.rating") > 0 && (
							<TripAdvisor
								reviewsCount={hotel.tripadvisor.num_reviews}
								rating={hotel.tripadvisor.rating}
							/>
						)}

					{!isMobile && get(hotel, "photos.length") > 0 && !printMode && (
						<SmartDPPatchwork photos={hotel.photos} onShowMore={openPhotosSlideshow} />
					)}

					{printMode && get(hotel, "photos.length") > 0 && (
						<SimpleProgressiveImg
							src={hotel.photos[0].url}
							resolution={resolution}
							sizes={PHOTO_PRINT_IMG_SIZES}
						/>
					)}

					{printMode ? (
						<div className="sdp-hotel-description__text">{hotel.summary}</div>
					) : (
						<div className="sdp-hotel-description__text">
							<LineClampViewMore
								enableShowMore={true}
								lineCount={isMobile ? 6 : 5}
								text={<span dangerouslySetInnerHTML={{ __html: hotel.summary }} />}
							/>
						</div>
					)}
				</div>

				{printMode ? (
					<>
						<FacilitySection facilities={hotel.facilityGroups} />
						<LocationSection hotel={hotel} googleAPIKey={googleAPIKey} />
						<SanitaryMessage
							intro={get(sanitaryInformations, "sanitaryInformations.intro")}
							details={get(sanitaryInformations, "sanitaryInformations.moreDetails")}
						/>
						<NegociatedList
							negociatedList={hotel.negotiated}
							specialOffer={specialOfferInfos}
						/>
						<div className="sdp-hotel-description__included">
							<div className="included">
								<div className="included__list">
									<div className="included__title">
										<FormattedMessage id="price.detail.included.title">
											{msg => <span>{`${msg} :`}</span>}
										</FormattedMessage>
									</div>
									<List
										items={get(hotel, `included`)}
										enableHTMLFormatting={true}
									/>
								</div>
								<div className="included__list">
									<div className="included__title">
										<FormattedMessage id="price.detail.not.included.title">
											{msg => <span>{`${msg} :`}</span>}
										</FormattedMessage>
									</div>
									<List
										items={get(hotel, `notIncluded`)}
										enableHTMLFormatting={true}
									/>
								</div>
							</div>
						</div>
						<UsefulInformation usefulInformations={hotel.usefulInformations} />
					</>
				) : (
					<Collapse
						expandIcon={({ isActive }) => {
							return (
								<i
									style={{
										transition: "transform .2s ease",
										transform: `rotate(${isActive ? 180 : 0}deg)`,
									}}
									className="icon icon--arrow-down-grey"
								/>
							);
						}}
					>
						<Collapse.Panel
							headerClass="sdp-hotel-description-item__header"
							header={
								<div className="sdp-hotel-description-item__title">
									<FormattedMessage id="sdp.hotel.description.facilities.label" />
								</div>
							}
						>
							<div className="sdp-hotel-description-item__body">
								<FacilitySection facilities={hotel.facilityGroups} />
							</div>
						</Collapse.Panel>
						<Collapse.Panel
							headerClass="sdp-hotel-description-item__header"
							header={
								<div className="sdp-hotel-description-item__title">
									<FormattedMessage id="sdp.hotel.description.location.label" />
								</div>
							}
						>
							<div className="sdp-hotel-description-item__body">
								<LocationSection hotel={hotel} googleAPIKey={googleAPIKey} />
							</div>
						</Collapse.Panel>
						<Collapse.Panel
							headerClass="sdp-hotel-description-item__header"
							header={
								<div className="sdp-hotel-description-item__title">
									<FormattedMessage id="product.sanitary.message.title" />
								</div>
							}
						>
							<div className="sdp-hotel-description-item__body">
								<SanitaryMessage
									intro={get(sanitaryInformations, "sanitaryInformations.intro")}
									details={get(
										sanitaryInformations,
										"sanitaryInformations.moreDetails"
									)}
								/>
							</div>
						</Collapse.Panel>
					</Collapse>
				)}
			</div>
		);

	return hotelDesc;
};

SmartDPHotelDescription.propTypes = {
	printMode: PropTypes.bool,
	hasSdpSpecialOffer: PropTypes.bool,
	specialOfferInfos: PropTypes.object,
	hotel: PropTypes.object,
	slideshow: PropTypes.shape({
		isSlideshowOpened: PropTypes.bool,
		photosType: PropTypes.string,
	}),
	closeSlideshow: PropTypes.func,
	openSlideshow: PropTypes.func,
	googleAPIKey: PropTypes.string,
};

SmartDPHotelDescription.defaultProps = {
	slideshow: {},
	hotel: {},
	specialOfferInfos: {},
	closeSlideshow: () => {},
	openSlideshow: () => {},
};

export default React.memo(SmartDPHotelDescription);
